// src/components/Layout.js
import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar/sidebar';
import Navbar from './navbar/navbar';
import ModalPassword from './modal/modalPassword';

const Layout = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [tipoAccion, setTipoAccion] = useState(""); // Estado para guardar si es "Entrada" o "Salida"

  const handleShowModal = (tipo) => {
    setTipoAccion(tipo); // Guarda si es "Entrada" o "Salida"
    setShowModal(true);  // Muestra el modal
  };  
  const handleCloseModal = (confirmed) => {
    if (confirmed) {
      setIsButtonDisabled(true); // Deshabilitar el botón si se confirma la acción
      // Guardar el estado y el tiempo en localStorage
      const now = new Date().getTime();
      localStorage.setItem('buttonDisabled', 'true');
      localStorage.setItem('disabledTime', now);
    }
    setShowModal(false);
  };

  useEffect(() => {
    // Verificar si el botón debe estar deshabilitado al cargar el componente
    const disabledTime = localStorage.getItem('disabledTime');
    if (disabledTime) {
      const now = new Date().getTime();
      const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;

      // Si han pasado menos de 2 horas, mantener el botón deshabilitado
      if (now - disabledTime < twoHoursInMilliseconds) {
        setIsButtonDisabled(true);
      } else {
        // Si han pasado más de 2 horas, eliminar la información de localStorage
        localStorage.removeItem('buttonDisabled');
        localStorage.removeItem('disabledTime');
      }
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className="layout-container">
        <Sidebar onOpenModal={handleShowModal} isButtonDisabled={isButtonDisabled} />
        <main>
          {children}
        </main>
        <ModalPassword
          show={showModal}
          type={tipoAccion}
          handleClose={handleCloseModal}
        />
      </div>
    </div>
  );
};

export default Layout;
