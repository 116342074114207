import React from 'react';
import { FormControl, InputGroup, Button } from 'react-bootstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';

const SearchInput = ({ searchTerm, setSearchTerm }) => {
  const handleClear = () => {
    setSearchTerm('');
  };

  return (
    <InputGroup className="mb-3">
      <FormControl
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="searchInput"
      />
      <InputGroup.Text>
        {searchTerm ? <FaTimes onClick={handleClear} /> : <FaSearch />}
      </InputGroup.Text>
      {searchTerm && (
        <Button variant="link" onClick={handleClear} className="input-clear-button">
        </Button>
      )}
    </InputGroup>
  );
};

export default SearchInput;

