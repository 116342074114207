import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FaUser, FaCalendarAlt, FaPhone, FaMapMarkerAlt, FaVenusMars } from 'react-icons/fa';
import GeneralApi from '../../services/GeneralApi';
import { toast } from 'react-toastify';

const ModalClient = ({ show, handleClose, onClientRegistered, client }) => {

    const [formData, setFormData] = useState({
        name: '',
        patern: '',
        matern: '',
        dateBirth: '',
        gender: '',
        address: '',
        phone: '',
    });
    const [age, setAge] = useState('');
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (client!=='') {
            setFormData({
                name: client.name || '',
                patern: client.patern || '',
                matern: client.matern || '',
                dateBirth: client.dateBirth || '',
                gender: client.gender || '',
                address: client.address || '',
                phone: client.phone || '',
            });
            setAge(calculateAge(client.dateBirth));
            setIsEditing(true);
        } 
    }, [client]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'dateBirth') {
            setAge(calculateAge(value));
        }
    };

    const handleReset = () => {
        setFormData({
            name: '',
            patern: '',
            matern: '',
            dateBirth: '',
            gender: '',
            address: '',
            phone: '',
        });
        setAge('');
        setIsEditing(false);
    };
    const calculateAge = (dateString) => {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let response;
            if (isEditing) {
                response = await GeneralApi.patch(`clients/update/${client.id}`, formData);
            } else {
                response = await GeneralApi.post('clients/register', formData);
            }

            if (response.data.code === 201) {
                toast.success(isEditing ? 'Cliente actualizado exitosamente' : 'Cliente registrado exitosamente');
                handleClose();
                handleReset();  // Resetea el formulario después de guardar             
                onClientRegistered();
            } else {
                if (Array.isArray(response.data.message)) {
                    const errorMessage = response.data.message.join('\n');
                    toast.error(errorMessage);
                } else {
                    toast.error(response.data.message || 'Error al procesar la solicitud');
                }
            }
        } catch (error) {
            console.error('Error en el proceso:', error);
            toast.error('Hubo un problema al procesar la solicitud');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal show={show} onHide={() => {
                    handleReset(); // Resetea al cerrar el modal
                    handleClose();
                }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Editar Cliente' : 'Registrar Cliente'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group controlId="formBasicName" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaUser />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nombre (s)"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formBasicPatern" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaUser />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Apellido Paterno"
                                            name="patern"
                                            value={formData.patern}
                                            onChange={handleChange}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formBasicMatern" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaUser />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Apellido Materno"
                                            name="matern"
                                            value={formData.matern}
                                            onChange={handleChange}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formBasicDateBirth" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaCalendarAlt />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="date"
                                            placeholder="Fecha de Nacimiento"
                                            name="dateBirth"
                                            value={formData.dateBirth ? new Date(formData.dateBirth).toISOString().split('T')[0] : ''}
                                            onChange={handleChange}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formBasicAge" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaCalendarAlt />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Edad"
                                            name="age"
                                            value={age}
                                            readOnly
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="formBasicGender" className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaVenusMars />
                                        </InputGroup.Text>
                                        <Form.Select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Género</option>
                                            <option value="Masculino">Masculino</option>
                                            <option value="Femenino">Femenino</option>
                                            <option value="Otro">Otro</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group controlId="formBasicAddress" className="mb-4">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FaMapMarkerAlt />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Dirección"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicPhone" className="mb-4">
                        <InputGroup>
                            <InputGroup.Text>
                                <FaPhone />
                            </InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Teléfono"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </InputGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button variant="secondary" onClick={() => {
                                    handleReset();  // Asegura el reseteo del formulario al cerrar
                                    handleClose();
                                }} className="me-2">
                                Cancelar
                            </Button>
                            <Button variant="danger" type="submit" disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalClient;
