// api.js
import axios from 'axios';

const GeneralApi = axios.create({
  baseURL: 'https://srv602707.hstgr.cloud/fussion/', 
  timeout: 20000, 
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptores para manejar solicitudes y respuestas
GeneralApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

GeneralApi.interceptors.response.use(
  response => {
    // procesar la respuesta aquí antes de devolverla
    return response;
  },
  error => {
    // manejar errores de respuesta aquí
    return Promise.reject(error);
  }
);

export default GeneralApi;
