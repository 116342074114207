import React, { useEffect, useState, useRef } from "react";
import { Button, Spinner } from 'react-bootstrap';
import { TableCustom } from "../../components/Table/Table";
import GeneralApi from "../../services/GeneralApi";
import { FaPlus } from 'react-icons/fa';
import ModalUser from "./ModalUser";
import SearchInput from "../../components/searchInput/searchInput";
import ToastCustom from "../../components/Toast/ToastCustom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { toast } from "react-toastify";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const headers = [
  { key: 'fullName', label: 'Nombre', align: 'center' },
  { key: 'email', label: 'Correo', align: 'center' },
  { key: 'roles', label: 'Rol', align: 'center' },
  { key: 'actions', label: 'Acciones', align: 'center'}
];

const Users = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');
  const [userToEdit, setUserToEdit] = useState('');
  const formRef = useRef(null);

  // Mapeo de roles
  const roleMapping = {
    manager: 'Gerente',
    admin: 'Administrativo',
    coach: 'Entrenador',
    superAdmin: 'Super Administrador',
    user: 'Usuario'
  };

  // Función para obtener la lista de usuarios
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await GeneralApi.put('users/findAll', {
        page: 1,
        elementsByPage: 10
      });
      const users = response?.data?.entity?.users ?? [];
      const usersWithFullName = users.map(user => ({
        ...user,
        fullName: `${user.name} ${user.patern} ${user.matern}`.trim()
      }));
      const rowsWithActions = usersWithFullName.map(user => ({
        ...user,
        roles: user.roles.map(role => roleMapping[role] || role).join(', '),
        actions: user.roles.includes('superAdmin') || user.roles.includes('manager') ? null : (
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button variant="warning" onClick={() => handleEdit(user)}><CiEdit /></Button>
            <Button variant="danger" onClick={() => handleShowConfirmModal(user)}><CiTrash /></Button>
          </div>
        )
      }));
      setRows(rowsWithActions);
      setFilteredRows(rowsWithActions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Obtener usuarios al montar el componente
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = rows.filter(row =>
      row.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
    setFilteredRows(filtered);
  }, [searchTerm, rows]);

  const handleRefresh = () => {
    fetchUsers();
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setUserToEdit('');
    if (formRef.current) {
      formRef.current.reset(); // Resetear el formulario
    }
  };

  const handleShowConfirmModal = (user) => {
    setUserToDelete(user);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setUserToDelete('');
  };

  const handleConfirmDelete = async () => {
    if (userToDelete!== '') {
      setLoading(true);
      try {
        const response = await GeneralApi.delete(`users/remove/${userToDelete.id}`);
        if (response.data.code === 201) {
          toast.success('Usuario eliminado correctamente');
          handleRefresh();
        } else {
          const errorMessage = Array.isArray(response.data.message)
            ? response.data.message.join('\n')
            : response.data.message || 'Error al eliminar el usuario';
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error('Error al eliminar el usuario:', error);
        toast.error('Hubo un problema al intentar eliminar el usuario');
      }
      setLoading(false);
    }
    handleCloseConfirmModal();
  };

  const handleEdit = (user) => {
    setUserToEdit(user);
    handleShowModal();
  };

  return (
    <div className="main-content">
      <ToastCustom />
     
        <h3>Usuarios</h3>
        <hr className="divider" />
        <div className="d-flex justify-content-between mb-3">
          <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Button variant="danger" onClick={handleShowModal}>
            <FaPlus /> Agregar
          </Button>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <TableCustom headers={headers} rows={filteredRows}/>
        )}
      
      <ModalUser show={showModal} handleClose={handleCloseModal} onUserRegistered={handleRefresh} user={userToEdit}/>
      <ConfirmDeleteModal
        show={showConfirmModal}
        onHide={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        user={userToDelete}
      />
    </div>
  );
};

export default Users;
