import GeneralApi from "../services/GeneralApi";
import { toast } from "react-toastify";
export const openDoor = async (user) => {
  const data = { clientId: user.id };

  try {
    const response = await GeneralApi.post(`auth/allow-access-client`, data);
    if (response.data.code === 201) {
      toast.success(response.data.message + "a : " + user.fullName);
    } else {
      const errorMessage = Array.isArray(response.data.message)
        ? response.data.message.join("\n")
        : response.data.message || "Error al accesar cliente";
      toast.error(errorMessage);
    }
  } catch (error) {
    console.error("Error al accesar/salir:", error);
    toast.error("Hubo un problema al intentar accesar/salir");
  }
  console.log("se le abrió la puerta a ", user);
};
