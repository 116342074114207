import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';

// Crea el contexto
const AuthContext = createContext();

// Proveedor del contexto
export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [roles, setRoles] = useState([]);
  // Función para cerrar sesión
  const logout = () => {
    console.log('entro al logout')
    setIsAuthenticated(false);
    setRoles([]);                     // Limpiar los roles
    localStorage.removeItem('token');
    localStorage.removeItem('fullName');
    localStorage.removeItem('email');
    localStorage.removeItem('roles');
    localStorage.removeItem('password')
    localStorage.removeItem('disabledTime')
    return <Navigate to="/" />;
  };
  useEffect(() => {
    // Al cargar el componente, verifica si el token existe
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
  
        // Verifica si el token ha expirado
        if (decodedToken.exp > currentTime) {
          // Solo actualiza el estado si es necesario
          if (!isAuthenticated) {
            setIsAuthenticated(true);
          }
  
          // Solo actualiza los roles si son diferentes
          const decodedRoles = decodedToken.roles || [];
          if (JSON.stringify(roles) !== JSON.stringify(decodedRoles)) {
            setRoles(decodedRoles);
          }
        } else {
          // Si el token ha expirado, eliminarlo y cerrar sesión
          console.log('debe cerrar sesión')
          logout();
        }
      } catch (error) {
        console.log('debe cerrar sesión')

        // Si ocurre un error al decodificar el token (ej. token malformado)
        logout();
      }
    }
  }, [isAuthenticated, roles, logout]);
  

  // Función para iniciar sesión
  const login = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const { name, patern, matern, email, roles } = decodedToken;
      const fullName = `${name} ${patern} ${matern}`;

      setIsAuthenticated(true);
      setRoles(roles || []);
      localStorage.setItem('token', token);
      localStorage.setItem('fullName', fullName);
      localStorage.setItem('email', email);
      localStorage.setItem('roles', JSON.stringify(roles || []));
    } catch (error) {
      console.error("Error decodificando el token en login:", error);
    }
  };



  return (
    <AuthContext.Provider value={{ isAuthenticated, roles, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// Hook para usar el contexto
export function useAuth() {
  return useContext(AuthContext);
}
