import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import GeneralApi from "../../services/GeneralApi";
import { toast } from "react-toastify";

const ModalUser = ({ show, handleClose, onUserRegistered, user }) => {
  const [formData, setFormData] = useState({
    name: "",
    patern: "",
    matern: "",
    email: "",
    password: "",
    roles: [],
  });
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      // Si se recibe un usuario, configurar el estado para edición
      setFormData({
        name: user.name || "",
        patern: user.patern || "",
        matern: user.matern || "",
        email: user.email || "",
        password: "", // La contraseña no se prellena en la edición
        roles: user.roles || [],
      });
      setIsEditing(true);
    } else {
      // Si no hay usuario, configurar el estado para creación
      setFormData({
        name: "",
        patern: "",
        matern: "",
        email: "",
        password: "",
        roles: [],
      });
      setIsEditing(false);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "roles") {
        if (checked) {
            setFormData((prevState) => ({
                ...prevState,
                roles: [...prevState.roles, value]
            }));
        } else {
            // Si está desmarcado, lo eliminamos del array
            setFormData((prevState) => ({
                ...prevState,
                roles: prevState.roles.filter(role => role !== value)
            }));
        }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let response;
      if (isEditing) {
        // Actualizar usuario
        response = await GeneralApi.patch(`users/update/${user.id}`, formData);
      } else {
        // Crear usuario
        response = await GeneralApi.post("users/register", formData);
      }

      if (response.data.code === 201) {
        toast.success(
          isEditing
            ? "Usuario actualizado exitosamente"
            : "Usuario registrado exitosamente"
        );
        handleClose(); // Cierra el modal en caso de éxito
        onUserRegistered();
      } else {
        if (Array.isArray(response.data.message)) {
          // Une los mensajes con saltos de línea
          const errorMessage = response.data.message.join("\n");
          toast.error(errorMessage);
        } else {
          toast.error(
            response.data.message || "Error al procesar la solicitud"
          );
        }
      }
    } catch (error) {
      console.error("Error en el proceso:", error);
      toast.error("Hubo un problema al procesar la solicitud");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Editar Usuario" : "Registrar Usuario"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formBasicName" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Nombre (s)"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPatern" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Apellido Paterno"
                      name="patern"
                      value={formData.patern}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FaEnvelope />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="Correo"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicMatern" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Apellido Materno"
                      name="matern"
                      value={formData.matern}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                      minLength={8} // Contraseña debe tener al menos 8 caracteres
                      pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,}"
                      title="La contraseña debe contener al menos una mayúscula, una minúscula y un número, y debe tener al menos 8 caracteres"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicRoles" className="mb-4">
                  <Form.Label>Selecciona el/los rol/es</Form.Label>

                  <Form.Check
                    type="checkbox"
                    label="Gerente"
                    name="roles"
                    value="manager"
                    checked={formData.roles.includes("manager")}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Administrativo"
                    name="roles"
                    value="admin"
                    checked={formData.roles.includes("admin")}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Entrenador"
                    name="roles"
                    value="coach"
                    checked={formData.roles.includes("coach")}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={handleClose}
                className="me-2"
              >
                Cancelar
              </Button>
              <Button variant="danger" type="submit" disabled={loading}>
                {loading ? "Guardando..." : "Guardar"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalUser;
