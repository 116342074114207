import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify'; 
import GeneralApi from '../../services/GeneralApi';
export const InfoUserModal = ({ show, handleClose, user, handleConfirm }) => {
  const [lastPayment, setLastPayment] = useState(user.lastPayment ? new Date(user.lastPayment).toISOString().substr(0, 10) : '');
  const [nextPayment, setNextPayment] = useState(user.nextPayment ? new Date(user.nextPayment).toISOString().substr(0, 10) : '');
  const userRole = JSON.parse(localStorage.getItem('roles')); 

  const isEditable = userRole.includes('superAdmin') || userRole.includes('manager');

  const handleSave = async () => {
    const formData = {
      id: user.id,
      lastPayment,
      nextPayment,
    };

    try {
      const response = await GeneralApi.put('clients/updateSuscriptionDates', formData);
      if (response.data.code === 201) {
        handleClose();
        toast.success(response.data.entity[0]);
      } else {
        const errorMessage = Array.isArray(response.data.message)
          ? response.data.message.join('\n')
          : response.data.message || 'Error al actualizar las fechas de pago';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error al actualizar las fechas de pago:', error);
      toast.error('Error al actualizar las fechas de pago. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Información del Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Nombre Completo:</strong> {user.fullName}</p>
        {isEditable ? (
          <>
            <Form.Group controlId="formLastPayment">
              <Form.Label>Último Pago</Form.Label>
              <Form.Control
                type="date"
                value={lastPayment}
                onChange={(e) => setLastPayment(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNextPayment">
              <Form.Label>Próximo Pago</Form.Label>
              <Form.Control
                type="date"
                value={nextPayment}
                onChange={(e) => setNextPayment(e.target.value)}
              />
            </Form.Group>
          </>
        ) : (
          <>
            <p><strong>Último Pago:</strong> {new Date(user.lastPayment).toLocaleDateString()}</p>
            <p><strong>Próximo Pago:</strong> {new Date(user.nextPayment).toLocaleDateString()}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        {isEditable && (
          <Button variant="danger" onClick={handleSave}>
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
