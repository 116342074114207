// Navbar.js
import React, { useEffect, useState } from "react";
import { CiMenuBurger } from "react-icons/ci";
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from '../../AuthContext'; // Importa el contexto de autenticación

const Navbar = () => {
    const [userName, setUserName] = useState("");
    const [roles, setRoles] = useState("")
    const { logout } = useAuth(); // Obtén la función logout del contexto

    useEffect(() => {
        setUserName(localStorage.getItem("fullName"));
        const rolesFromStorage = JSON.parse(localStorage.getItem("roles"));
        const roles = rolesFromStorage.join(", ");
        setRoles(roles);
    }, []);

    const handleLogout = () => {
        logout(); 
        window.location.href = "/";
    };
    
    return (
        <div className="container-fluid">
            <nav className="navbar fixed-top navbar-light bg-dark" style={{ backgroundColor: "#C20114" }}>
               { /* <button className="navbar-toggler" type="button" data-toggle="collapse" style={{ marginLeft: "5px" }}>
                    <CiMenuBurger style={{ color: "white" }} />
                </button> */ } 
                <a></a>
                <Dropdown className="drop-profile">
                    <Dropdown.Toggle variant="danger" id="dropdown-basic">
                        {userName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item>Rol: {roles}</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </nav>
        </div>
    );
}

export default Navbar;
