import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { jwtDecode } from 'jwt-decode';
function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();

  // Verifica el token en localStorage si no está autenticado
  if (!isAuthenticated) {
    const token = localStorage.getItem('token');
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Verifica si el token ha expirado
        if (decodedToken.exp <= currentTime) {
          localStorage.removeItem('token');
          return <Navigate to="/" />;
        }

        // Si el token es válido, considera al usuario autenticado
        return children;
      } catch (e) {
        // En caso de error al decodificar el token, redirige al login
        localStorage.removeItem('token');
        return <Navigate to="/" />;
      }
    }

    // Si no hay token, redirige al login
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;
