// src/components/LoginForm.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ButtonGroup, Toast } from 'react-bootstrap';
import { BsFacebook, BsInstagram, BsTiktok  } from "react-icons/bs";
import GeneralApi from '../../services/GeneralApi';
import logo from '../../img/LogoWhite.png'
import './login.css'; 
import { toast } from 'react-toastify';
import ToastCustom from '../../components/Toast/ToastCustom';
import { useAuth } from '../../AuthContext';



const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate(); // Inicializa el hook useNavigate

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useAuth(); // Obtiene la función login del contexto

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await GeneralApi.post('auth/login', formData);
      const data = response.data;
      if (data.code === 201) {
        const { token } = data.entity;
        
        // Usa la función login del contexto
        login(token);

        // Muestra un toast de éxito
        toast.success('Usuario autenticado exitosamente');

        // Redirige a /home después de un inicio de sesión exitoso
        navigate('/home');
      } else {
        // Maneja el caso en el que el código no es 201
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Ocurrió un error. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="login-container">
       <ToastCustom />
       <Row xs="auto">
        <Col className='social-bar'>
          <a href='https://www.facebook.com/profile.php?id=61552922941118' target='_blank' rel='noopener noreferrer'>
            <BsFacebook className='social-icons' />
          </a>
          <a href='https://www.instagram.com/fussiongimnasio/' target='_blank' rel='noopener noreferrer'>
            <BsInstagram className='social-icons' />
          </a>
          <a href='https://www.tiktok.com/@gimnasio.fussion' target='_blank' rel='noopener noreferrer'>
            <BsTiktok className='social-icons' />
          </a>
        </Col>
        
      </Row>
      <Row className="justify-content-center align-items-center vh-100">
        <Col md={4} className="login-form">
        <img src={logo} alt="logo" className="logo" />
        <Form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
        <h5 className="mb-4" style={{color: "white", textAlign: "center"}}>Inicio de sesión</h5>

          <Form.Group controlId="formBasicUsername" className="mb-2" >
            <Form.Control
                  type="text"
                  placeholder="Correo"
                  name="email"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  className='inputLogin'
                />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-1 mt-3"  >
            <Form.Control
                type="password"
                placeholder="Contraseña"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className='inputLogin'
              />
            </Form.Group>
            <div className="mb-3 text-end">
              <a href="/forgot-password" className="forgot-password-link">¿Olvidaste tu contraseña?</a>
            </div>
            <div className="text-center">
               <Button variant="danger" type="submit" className="mt-3 mb-3" disabled={loading} style={{minWidth: '150px' }}>
                  {loading ? 'Ingresando...' : 'Ingresar'}
               </Button>
            </div>
            
            
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
