// ToastCustom.js
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastCustom = () => {
    return <ToastContainer 
    position="bottom-right"
    theme="dark"/>;
};

export default ToastCustom;
