import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/LogoWhite.png";
import {
  FaUsersLine,
  FaUsersGear,
} from "react-icons/fa6";
import { ImExit, ImEnter  } from "react-icons/im";
import { FaCashRegister, FaWeight, FaHome, FaDoorOpen } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { useAuth } from "../../AuthContext";
import "./sidebar.css";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const Sidebar = ({ onOpenModal, isButtonDisabled }) => {
  const [timeRemaining, setTimeRemaining] = useState("");

  const { roles } = useAuth();

  useEffect(() => {
    if (isButtonDisabled) {
      const disabledTime = localStorage.getItem("disabledTime");
      if (disabledTime) {
        const now = new Date().getTime();
        const remainingTime = parseInt(disabledTime) + 2 * 60 * 60 * 1000 - now;

        if (remainingTime > 0) {
          const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
          setTimeRemaining(`${hours}h ${minutes}m`);
        } else {
          setTimeRemaining("");
        }
      }
    } else {
      setTimeRemaining("");
    }
  }, [isButtonDisabled]);

  const renderTooltip = (tipo, props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      {isButtonDisabled
        ? `Tiempo restante: ${timeRemaining}`
        : `Liberar ${tipo}`}
    </Tooltip>
  );

  return (
    <div className="sidebar">
      <div className="d-flex justify-content-center p-2">
        <img src={logo} alt="Logo" className="logo-sidebar" />
      </div>
      <br />
      <ul className="nav nav-pills">
        <li className="nav-item text-white fs-7">
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? "nav-link text-white active-link"
                : "nav-link text-white"
            }
          >
            <FaHome className="icons-custom" />
            Inicio
          </NavLink>
        </li>
        <li className="nav-item text-white fs-7">
          <NavLink
            to="/clients"
            className={({ isActive }) =>
              isActive
                ? "nav-link text-white active-link"
                : "nav-link text-white"
            }
          >
            <FaUsersLine className="icons-custom" />
            Clientes
          </NavLink>
        </li>
        {/* 
       <li className="nav-item text-white fs-7">
          <NavLink
            to="/sale_point"
            className={({ isActive }) => isActive ? 'nav-link text-white active-link' : 'nav-link text-white'}
          >
            <FaCashRegister className="icons-custom" />Punto de venta
          </NavLink>
        </li>
        <li className="nav-item text-white fs-7">
          <NavLink
            to="/inventory"
            className={({ isActive }) => isActive ? 'nav-link text-white active-link' : 'nav-link text-white'}
          >
            <FaClipboardList className="icons-custom" />Inventario
          </NavLink>
        </li> */}
        {(roles.includes("superAdmin") || roles.includes("manager")) && (
          <>
            <li className="nav-item text-white fs-7">
              <NavLink
                to="/payments"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white active-link"
                    : "nav-link text-white"
                }
              >
                <MdOutlineAttachMoney className="icons-custom" />
                Gestión de pagos
              </NavLink>
            </li>
            <li className="nav-item text-white fs-7">
              <NavLink
                to="/historic_payments"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white active-link"
                    : "nav-link text-white"
                }
              >
                <BiMoneyWithdraw className="icons-custom" />
                Histórico de pagos
              </NavLink>
            </li>
          </>
        )}

        {/* 
        <li className="nav-item text-white fs-7">
          <NavLink
            to="/client_tracking"
            className={({ isActive }) => isActive ? 'nav-link text-white active-link' : 'nav-link text-white'}
          >
            <FaWeight className="icons-custom" />Seguimiento de clientes
          </NavLink>
        </li> */}
        {(roles.includes("superAdmin") || roles.includes("manager")) && (
          <li className="nav-item text-white fs-7">
            <NavLink
              to="/users"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white active-link"
                  : "nav-link text-white"
              }
            >
              <FaUsersGear className="icons-custom" />
              Usuarios FUSSION
            </NavLink>
          </li>
        )}
      </ul>
      <div className="button-container">
  <OverlayTrigger
    placement="right"
    overlay={renderTooltip("Entrada")}
  >
    <div className="button-access2">
      <Button variant="danger" onClick={() => onOpenModal("Entrada")}>
        <ImEnter />{" "}
        {"Entrada"}
      </Button>
    </div>
  </OverlayTrigger>

  <OverlayTrigger
    placement="right"
    overlay={renderTooltip("Salida")}
  >
    <div className="button-access">
      <Button variant="danger" onClick={() => onOpenModal("Salida")}>
        <ImExit />{" "}
        {"Salida"}
      </Button>
    </div>
  </OverlayTrigger>
</div>


    </div>
  );
};

export default Sidebar;
