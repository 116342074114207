import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ModalCopyClientId = ({ show, handleClose, clientId }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(clientId)
            .then(() => {
                toast.success('ID copiado al portapapeles');
            })
            .catch(err => {
                console.error('Error al copiar el ID: ', err);
                toast.error('Hubo un error al copiar el ID');
            });
    };
    const handleReset = () =>{
        clientId=''
    }

    return (
        <Modal show={show} onHide={() => {
            handleReset(); // Resetea al cerrar el modal
            handleClose();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>¡Cliente Nuevo!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label> Registra las huellas del cliente, copia el código para ingresarlo en
                    modulo central:</Form.Label>
                    <Form.Control type="text" value={clientId} readOnly />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
            handleReset(); // Resetea al cerrar el modal
            handleClose();
        }}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={copyToClipboard}>
                    <FaCopy /> Copiar ID
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCopyClientId;
