import React, { useEffect, useState } from "react";
import { Container, Row, Card, Spinner } from "react-bootstrap";
import logo from "../../img/logoGym.png";
import GeneralApi from "../../services/GeneralApi";
import { toast } from "react-toastify";
import ToastCustom from "../../components/Toast/ToastCustom";
import { CiUser } from "react-icons/ci";
import { CgGym } from "react-icons/cg";

const Home = () => {
  const userName = localStorage.getItem("fullName");
  const [clients, setClients] = useState([]);
  const [clientCount, setClientCount] = useState(0);
  const [loadingClientCount, setLoadingClientCount] = useState(true); // Estado de carga para clientCount
  const [loadingClients, setLoadingClients] = useState(true); // Estado de carga para clientes

  useEffect(() => {
    const fetchClientCount = async () => {
      try {
        const response = await GeneralApi.get("clients/findInside");

        if (response.data.code === 201) {
          setClientCount(response.data.entity.count);
        } else {
          const errorMessage = Array.isArray(response.data.message)
            ? response.data.message.join("\n")
            : response.data.message || "Error al obtener el conteo de clientes";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error al obtener el conteo de clientes:", error);
        toast.error("Hubo un problema al intentar obtener el conteo de clientes");
      } finally {
        setLoadingClientCount(false); // Finalizar la carga de clientCount
      }
    };

    const fetchAllClients = async () => {
      try {
        const responseAll = await GeneralApi.put("clients/findAll", {
          page: 1,
          elementsByPage: 5000,
        });

        if (responseAll.data.code === 201) {
          // Filtrar clientes activos
          const activeClients = responseAll.data.entity.clients.filter(
            (client) => client.isActive === true
          );
          setClients(activeClients); // Asignar los clientes activos
        } else {
          const errorMessage = Array.isArray(responseAll.data.message)
            ? responseAll.data.message.join("\n")
            : responseAll.data.message || "Error al obtener la lista de clientes";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
        toast.error("Hubo un problema al intentar obtener la lista de clientes");
      } finally {
        setLoadingClients(false); // Finalizar la carga de clientes
      }
    };

    const fetchClients = async () => {
      await Promise.all([fetchClientCount(), fetchAllClients()]);
    };

    fetchClients();
  }, []);

  return (
    <div
      className="main-content"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "100vh",
        minHeight: "80vh",
        textAlign: "center",
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(" +
          logo +
          ")",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: "20px",
        marginTop: "100px",
      }}
    >
      <ToastCustom />

      <h1
        style={{
          fontSize: "2rem",
          color: "#555",
          marginBottom: "20px",
          fontWeight: "bold",
        }}
      >
        ¡Bienvenido {userName}!
      </h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <Card
          className="text-center"
          style={{
            width: "300px",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "none",
          }}
        >
          <Card.Body>
            <div
              style={{
                fontSize: "40px",
                color: "#00AEEF",
                marginBottom: "10px",
              }}
            >
              <CgGym style={{ color: 'red' }} />
            </div>
            <Card.Title
              style={{
                fontSize: "1rem",
                fontWeight: "normal",
                color: "#6c757d",
              }}
            >
              Usuarios en Gimnasio
            </Card.Title>
            <p style={{ fontSize: "2rem", fontWeight: "bold", color: "#333" }}>
              {loadingClientCount ? (
                <Spinner animation="border"  />
              ) : (
                clientCount
              )}
            </p>
          </Card.Body>
        </Card>

        <Card
          className="text-center"
          style={{
            width: "300px",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "none",
          }}
        >
          <Card.Body>
            <div
              style={{
                fontSize: "40px",
                color: "#00AEEF",
                marginBottom: "10px",
              }}
            >
              <CiUser style={{ color: 'red' }} />
            </div>
            <Card.Title
              style={{
                fontSize: "1rem",
                fontWeight: "normal",
                color: "#6c757d",
              }}
            >
              Usuarios activos
            </Card.Title>
            <p style={{ fontSize: "2rem", fontWeight: "bold", color: "#333" }}>
              {loadingClients ? (
                <Spinner animation="border"/>
              ) : (
                clients.length
              )}
            </p>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Home;
