import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import GeneralApi from '../../services/GeneralApi';
import { toast } from 'react-toastify';

const ModalPassword = ({ show, type, handleClose }) => {
    const [formData, setFormData] = useState({
        password: localStorage.getItem('password') || '', // Inicializa desde localStorage solo una vez
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value }); // Actualiza el campo de contraseña
    };


    const handleConfirm = () => {
        handleClose(true); // Confirmar acción
    };

    const handleCancel = () => {
        handleClose(false); // Cancelar acción
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        localStorage.setItem('password', formData.password); // Guarda la contraseña en localStorage
        const endpoint = type === 'Entrada' ? 'auth/allow-access' : 'auth/allow-exit'; // Determina el endpoint
        try {
            const response = await GeneralApi.post(endpoint, formData);
            if (response.data.code === 201) {
                handleConfirm();
                toast.success(response.data.message);
            } else {
                const errorMessage = Array.isArray(response.data.message)
                    ? response.data.message.join('\n')
                    : response.data.message || 'Error al acceder al usuario';
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Error en el acceso:', error);
            toast.error('Error en el acceso. Por favor, inténtelo de nuevo.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleCancel} size="md">
            <Modal.Header closeButton>
                <Modal.Title>¿Desea liberar {type}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Si desea permitir la {type} ingrese su contraseña</p>
                <Form onSubmit={handleSubmit}>
                    <div className="row">
                        <Form.Group controlId="formBasicPassword" className="mb-4">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FaLock />
                                </InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={formData.password} // Vinculado a formData.password
                                    onChange={handleChange}
                                    required
                                    minLength={8}
                                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%#*?&]{8,}"
                                    title="La contraseña debe contener al menos una mayúscula, una minúscula y un número, y debe tener al menos 8 caracteres."
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" onClick={handleCancel} className="me-2">
                            Cancelar
                        </Button>
                        <Button variant="danger" type="submit" disabled={loading}>
                            {loading ? 'Aceptando...' : 'Aceptar'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalPassword;
