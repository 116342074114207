import React from "react";
import Button from "react-bootstrap/Button";
import { CiEdit, CiTrash } from "react-icons/ci";

export const getTableConfig = (contentType, data, handleEdit, handleDelete) => {
  switch (contentType) {
    case "descuentos":
      return {
        headers: [
          { key: "name", label: "Nombre" },
          { key: "amountDiscount", label: "Descuento" },
          { key: "actions", label: "Acciones" },
        ],
        rows: data.map((item) => ({
          id: item.id,
          name: item.name,
          amountDiscount: `$ ${item.amountDiscount}`,
          actions: (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {/* <Button variant="warning" onClick={() => handleEdit(item)}>
                <CiEdit />
              </Button> */} 
              <Button variant="danger" onClick={() => handleDelete(item)}>
                <CiTrash />
              </Button>
            </div>
          ),
        })),
      };
    case "precios":
      return {
        headers: [
          { key: "name", label: "Nombre" },
          { key: "quantity", label: "Cantidad" },
          { key: "days", label: "Días" },
          { key: "actions", label: "Acciones" },
        ],
        rows: data.map((item) => ({
          id: item.id,
          name: item.name,
          quantity: `$ ${item.quantity}`,
          days: item.days,
          actions: (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
             {/* <Button variant="warning" onClick={() => handleEdit(item)}>
                <CiEdit />
              </Button> */} 
              <Button variant="danger" onClick={() => handleDelete(item)}>
                <CiTrash />
              </Button>
            </div>
          ),
        })),
      };
    case "tipos-pago":
      return {
        headers: [
          { key: "name", label: "Nombre" },
          { key: "actions", label: "Acciones" },
        ],
        rows: data.map((item) => ({
          id: item.id,
          name: item.name,
          actions: (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {/* <Button variant="warning" onClick={() => handleEdit(item)}>
                <CiEdit />
              </Button> */} 
              <Button variant="danger" onClick={() => handleDelete(item)}>
                <CiTrash />
              </Button>
            </div>
          ),
        })),
      };
    default:
      return { headers: [], rows: [] };
  }
};
