import React, { useEffect, useState } from "react";
import { Button, Spinner, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TableCustom } from "../../components/Table/Table";
import GeneralApi from "../../services/GeneralApi";
import SearchInput from "../../components/searchInput/searchInput";
import { CiEdit, CiTrash } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { FaDoorOpen, FaPlus, FaFingerprint,FaCircleInfo  } from "react-icons/fa6";
import ModalClient from "./ModalClient";
import ToastCustom from "../../components/Toast/ToastCustom";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { toast } from "react-toastify";
import { openDoor } from "../../utils/openDoor";
import ModalPaymentClient from "../Payments/ModalPaymentClient";
import { InfoUserModal } from "./ModalInfoClient";

const headers = [
  { key: "fullName", label: "Nombre", align: "center" },
  { key: "isActive", label: "Estatus", align: "center" },
  { key: "actions", label: "Acciones", align: "center" },
];

const Clients = () => {
  const [showModal, setShowModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [userToEdit, setUserToEdit] = useState("");
  const [userToDelete, setUserToDelete] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [userToPay, setUserToPay] = useState("");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 

  const fetchClients = async () => {
    setLoading(true); // Mostrar el spinner al iniciar la carga
    try {
      const response = await GeneralApi.put("clients/findAll", {
        page: 1,
        elementsByPage: 5000,
      });
      const users = response?.data?.entity?.clients ?? [];
      // Concatenar el nombre completo
      const usersWithFullName = users.map((user) => ({
        ...user,
        fullName: `${user.name} ${user.patern} ${user.matern}`.replace(/\s+/g, ' ').trim(),
      }));
      const rowsWithActions = usersWithFullName.map((user) => ({
        ...user,
        isActive: (
          <Badge
            bg={user.isActive ? "success" : "danger"}
            class="badge-large badge-pill"
            onClick={() => handleStatus(user)}
            style={{
              cursor: "pointer",
              fontSize: "0.7rem",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
            }}
          >
            {user.isActive ? "Activo" : "Inactivo"}
          </Badge>
        ),
        actions: (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button variant="warning" onClick={() => handleEdit(user)}>
              <CiEdit />
            </Button>
            <Button
              variant="danger"
              onClick={() => handleShowConfirmModal(user)}
            >
              <CiTrash />
            </Button>
             <Button
              variant="success"
              onClick={() => openDoor(user)}
              disabled={!user.isActive}
            >
              <FaDoorOpen />
            </Button>
            <Button
              variant="primary"
             onClick={() =>copyToClipboard(user.id)}
            >
              <FaFingerprint />
            </Button>
            <Button
              variant="info"
             onClick={() =>infoUser(user)}
            >
              <IoIosInformationCircleOutline  />
            </Button>
          </div>
        ),
      }));
      setRows(rowsWithActions);
      setFilteredRows(rowsWithActions);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const filtered = rows.filter(
      (row) =>
        row.fullName &&
        row.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(filtered);
  }, [searchTerm, rows]);

  const handleEdit = (user) => {
    setUserToEdit(user);
    handleShowModal();
  };
  const handleStatus = (user) => {
    setShowModalPayment(true);
    setUserToPay(user);
  };
  const handleShowConfirmModal = (user) => {
    setUserToDelete(user);
    setShowConfirmModal(true);
  };


  const copyToClipboard = (clientId) => {
    navigator.clipboard.writeText(clientId)
        .then(() => {
            toast.success('ID copiado al portapapeles');
        })
        .catch(err => {
            console.error('Error al copiar el ID: ', err);
            toast.error('Hubo un error al copiar el ID');
        });
};

const infoUser=(user)=>{
  setSelectedUser(user);
    setShowModalInfo(true);
}
const handleCloseInfo = () => {setShowModalInfo(false)
  setSelectedUser("")
}

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setUserToEdit("");
  };
  const handleCloseModalPayment = () => {
    setShowModalPayment(false);
    setUserToPay("");
  };
  const handleRefresh = () => {
    fetchClients();
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setUserToDelete("");
  };
  const handlePaymentSuccess = () => {
    handleRefresh();
    handleCloseModalPayment();
  };

 
  const handleConfirmDelete = async () => {
    if (userToDelete !== "") {
      setLoading(true);
      try {
        const response = await GeneralApi.delete(
          `clients/remove/${userToDelete.id}`
        );
        if (response.data.code === 201) {
          toast.success("Cliente eliminado correctamente");
          handleRefresh();
        } else {
          const errorMessage = Array.isArray(response.data.message)
            ? response.data.message.join("\n")
            : response.data.message || "Error al eliminar el cliente";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error al eliminar el cliente:", error);
        toast.error("Hubo un problema al intentar eliminar el cliente");
      }
      setLoading(false); // Ocultar el spinner después de eliminar
    }
    handleCloseConfirmModal();
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="main-content">
      <ToastCustom />
      <h3>Clientes</h3>
      <hr className="divider" />
      <div className="d-flex justify-content-between mb-3">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <OverlayTrigger
          placement="top" 
          overlay={
            <Tooltip id="tooltip-agregar">Agregar nuevo elemento</Tooltip>
          }
        >
          <Button variant="danger" onClick={handleShowModal}>
            <FaPlus /> Agregar
          </Button>
        </OverlayTrigger>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <TableCustom headers={headers} rows={filteredRows} />
      )}
      <ModalClient
        show={showModal}
        handleClose={handleCloseModal}
        onClientRegistered={handleRefresh}
        client={userToEdit}
      />
      <ConfirmDeleteModal
        show={showConfirmModal}
        onHide={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        user={userToDelete}
      />
      <ModalPaymentClient
        show={showModalPayment}
        handleClose={handleCloseModalPayment}
        client={userToPay}
        onPaymentSuccess={handlePaymentSuccess}
      />
      {selectedUser && <InfoUserModal
       show={showModalInfo} 
       handleClose={handleCloseInfo} 
       user={selectedUser}
      ></InfoUserModal>}
      
    </div>
  );
};

export default Clients;
